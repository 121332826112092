import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// Content Components
import Faqs from '../../elements/Faqs/Faqs'
import Hero from '../../elements/Hero/Hero'
import TestimonialCarousel from '../../elements/TestimonialCarousel/TestimonialCarousel'
import HubspotForm from '../../elements/HubspotForm/HubspotForm'

// Generic
import Button from '../../shared/Button/Button'
import Container from '../../shared/Container/Container'
import CurriculumTabs from '../../shared/CurriculumTabs/CurriculumTabs'
import Edge from '../../shared/Edge/Edge'
import HtmlContent from '../../shared/HtmlContent/HtmlContent'
import Image from '../../shared/Image/Image'
import Modal from '../../shared/Modal/Modal'
import Pill from '../../shared/Pill/Pill'
import StickyLayout from '../../shared/StickyLayout/StickyLayout'
import TwoColGrid from '../../shared/TwoColGrid/TwoColGrid'
import TwoColGridItem from '../../shared/TwoColGrid/TwoColGridItem'
import Embed from '../../shared/Embed/Embed'

// Utils
import toSlug from '../../../utils/toSlug'

// Local components
import CourseViewNav from './CourseViewNav'
import CourseViewSection from './CourseViewSection'
import CourseViewOverviewDetails from './CourseViewOverviewDetails'

const CourseView = ({ data }) => {
  const navItems = ['Overview', 'Curriculum', 'Course Dates', 'Finance', 'FAQs']

  const modalElement = useRef(null)

  const [currentNav, setCurrentNav] = useState(toSlug(navItems[0]))
  const [canUpdateNav, setCanUpdateNav] = useState(true)

  /**
   * Calculate the current scroll position and update the state if it's changed
   * @param  {Object} element
   * @param  {string} id
   * @return {Void}
   */
  const updateCurrentNavBasedOnScroll = (element, id) => {
    if (element.current) {
      // Why minus 1? Honestly I don't know. When clicking a nav link in the
      // `CourseViewNav` it was landing 1px too high, so this fixes it
      const elementTop = Math.round(element.current.getBoundingClientRect().top) - 1
      const elementBottom = Math.round(elementTop + element.current.offsetHeight) - 1

      // If the top of the viewport is within current element, set it as the
      // current nav item
      // Don't update if we're animating via a smooth scroll
      if (elementTop <= 0 && elementBottom >= 0 && canUpdateNav) {
        setCurrentNav(id)
      }
    }
  }

  /**
   * Open the modal in the child component
   * @param  {Object} event
   * @return {Void}
   */
  const handleOpenModal = (event) => {
    event.preventDefault()

    modalElement.current.showModal()
  }

  return (
    <>
      <Hero
        data={{
          primary: {
            hero_title: data.title,
            hero_intro: data.course_intro,
            hero_illustration: data.course_illustration,
            logo_group: data.logo_group
          }
        }}
        additionalContent={
          <>
            <div className='mt-8 sm:mt-12 flex flex-wrap'>
              <Button
                color='primary'
                className='mb-4 mr-4'
                icon
                {...data.apply_cta_link}
              >
                {data.call_to_action_text ? data.call_to_action_text : 'Talk to us'}
              </Button>
              <Button
                color='secondary'
                className='mb-4 mr-4'
                element='button'
                onClick={handleOpenModal}
                icon
              >
                Download Curriculum
              </Button>
            </div>
          </>
        }
      />
      <StickyLayout
        breakpoint='lg'
        nav={
          <CourseViewNav
            navItems={navItems}
            currentNav={currentNav}
            setCurrentNav={setCurrentNav}
            setCanUpdateNav={setCanUpdateNav}
            data={data}
          />
        }
      >
        {({ leftSpacingClassName }) => {
          return (
            <>
              <CourseViewSection
                id={toSlug('Overview')}
                className='pb-16 md:pb-32'
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Container>
                  <div className={leftSpacingClassName}>
                    <h2 className='mb-6'>{data.course_header.text}</h2>
                    <HtmlContent
                      html={data.course_body.html}
                      className='text-lg mb-12 c-prose'
                    />
                    <CourseViewOverviewDetails data={data} />

                    {(data?.course_video_embed?.html || data?.course_image_embed?.url) && (
                      <div className='mb-16'>
                        {data?.course_video_embed?.html ? (
                          <Embed
                            embed={data.course_video_embed}
                            thumbnail={data.course_image_embed.url ? data.course_image_embed : null}
                            title={data.course_video_embed_custom_title || data.course_video_embed.title}
                          />
                        ) : (
                          <Image
                            fallbackAlt={data.course_header.text}
                            {...data.course_image_embed}
                          />
                        )}
                      </div>
                    )}
                    <TwoColGrid>
                      {data.course_features.map((item, itemIndex) => {
                        return (
                          <TwoColGridItem
                            key={itemIndex}
                            icon={item.course_features_icon}
                            header={item.course_features_header}
                            body={item.course_features_body.html}
                          />
                        )
                      })}
                    </TwoColGrid>
                  </div>
                </Container>
              </CourseViewSection>
              <CourseViewSection
                id={toSlug('Curriculum')}
                className='bg-offwhite'
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Edge
                  location='top'
                  direction='right'
                  outerClassName='text-white'
                  innerClassName='text-offwhite'
                />
                <Container>
                  <div className={classNames('pt-12 pb-16 sm:pb-32', leftSpacingClassName)}>
                    <div className='max-w-2xl'>
                      <h2 className='mb-6 sm:mb-4'>{data.course_curriculum_title.text}</h2>
                      <CurriculumTabs
                        tabs={data.course_curriculum_tabs.map((tab) => {
                          return {
                            value: tab.course_curriculum_tab_value.text,
                            header: tab.course_curriculum_tab_header.text,
                            body: tab.course_curriculum_tab_body.html,
                            footnote: tab.course_curriculum_tab_footnote.html
                          }
                        })}
                      />
                    </div>
                  </div>
                </Container>
                {data.course_curriculum_additional_info_grid.length > 0 && (
                  <div className='relative -mt-12 sm:-mt-20 mb-4'>
                    <Container>
                      <div className={classNames('relative z-10', leftSpacingClassName)}>
                        <div className='bg-white shadow-lg p-4 sm:p-6 md:p-16 xl:-ml-16'>
                          <TwoColGrid>
                            {data.course_curriculum_additional_info_grid.map((item, itemIndex) => {
                              return (
                                <TwoColGridItem
                                  key={itemIndex}
                                  icon={item.course_curriculum_additional_info_grid_icon}
                                  header={item.course_curriculum_additional_info_grid_header}
                                  body={item.course_curriculum_additional_info_grid_body.html}
                                />
                              )
                            })}
                          </TwoColGrid>
                        </div>
                      </div>
                    </Container>
                    <div className='absolute bottom-0 left-0 w-full h-1/3 bg-white' />
                  </div>
                )}
              </CourseViewSection>
              <CourseViewSection
                id={toSlug('Course Dates')}
                className='mb-12 pt-12 sm:pt-16 md:pt-24'
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Container>
                  <div className={leftSpacingClassName}>
                    <h2 className='mb-6'>{data.course_dates_title.text}</h2>
                    <div className='space-y-6'>
                      {data.course_dates.map((item, itemIndex) => {
                        return (
                          <div
                            key={itemIndex}
                            className='shadow-lg sm:flex items-center justify-between sm:space-x-4 px-4 sm:px-10 py-6 sm:py-12 text-center sm:text-left'
                          >
                            <div className='mb-6 sm:mb-0'>
                              {item.course_dates_extra_label.text && (
                                <Pill
                                  className='mb-2'
                                >
                                  {item.course_dates_extra_label.text}
                                </Pill>
                              )}
                              <h4 className='text-primary mb-1'>{item.course_dates_date}</h4>
                              <p>{item.course_dates_location.text}</p>
                            </div>

                            {item.course_dates_display_apply_now_button ? (
                              <Button
                                color='primary'
                                className='w-full sm:w-auto'
                                icon
                                {...data.apply_cta_link}
                              >
                                Apply Now
                              </Button>
                            ) : null}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </Container>
              </CourseViewSection>
              <CourseViewSection
                id={toSlug('Finance')}
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Container>
                  <div className={classNames('pt-12 mb-16', leftSpacingClassName)}>
                    <h2 className='mb-16'>{data.course_finance_title.text}</h2>
                    {(data.course_finance_rows_with_icon.length > 0) && (
                      <ul className='space-y-10 mb-12 max-w-3xl'>
                        {data.course_finance_rows_with_icon.map((item, itemIndex) => {
                          return (
                            <li
                              key={itemIndex}
                              className='flex'
                            >
                              <div className='w-12 flex-grow-0 flex-shrink-0'>
                                <Image
                                  fallbackAlt={data.course_finance_title.text}
                                  {...item.course_finance_row_icon}
                                />
                              </div>
                              <div className='pl-6'>
                                <HtmlContent
                                  html={item.course_finance_row_body.html}
                                  className='text-lg c-prose'
                                />
                                {(item.cta_label && item.cta_link.url) && (
                                  <Button
                                    className='mt-4'
                                    color='inline'
                                    icon
                                    {...item.cta_link}
                                  >
                                    {item.cta_label}
                                  </Button>
                                )}
                              </div>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                    {(data.course_finance_rows.length > 0) && (
                      <ul className='space-y-10 mb-12'>
                        {data.course_finance_rows.map((item, itemIndex) => {
                          return (
                            <li
                              key={itemIndex}
                              className='shadow-lg py-10 px-18'
                            >
                              <HtmlContent
                                html={item.body.html}
                                className='text-lg c-prose'
                              />
                              {(item.cta_label && item.cta_link.url) && (
                                <Button
                                  className='mt-4'
                                  color='inline'
                                  icon
                                  {...item.cta_link}
                                >
                                  {item.cta_label}
                                </Button>
                              )}
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </div>
                </Container>
                {data.course_testimonial_carousel?.document?.data.testimonial_carousel_items && (
                  <TestimonialCarousel
                    items={data.course_testimonial_carousel.document.data.testimonial_carousel_items}
                    preSwiperInnerClassName={leftSpacingClassName}
                    itemClassName='lg:max-w-none'
                  />
                )}
              </CourseViewSection>
              <CourseViewSection
                id={toSlug('FAQs')}
                className='pb-16 md:pb-32 pt-12 mb-4'
                updateCurrentNavBasedOnScroll={updateCurrentNavBasedOnScroll}
              >
                <Container>
                  <div className={leftSpacingClassName}>
                    <h2 className='mb-10'>{data.course_faqs_title.text}</h2>
                    <Faqs
                      canAnimate
                      className='mb-12'
                      items={data.course_faqs_tabs.map((faq) => {
                        return {
                          header: faq.course_faqs_header.text,
                          body: (
                            <HtmlContent
                              html={faq.course_faqs_body.html}
                              className='c-prose'
                            />
                          )
                        }
                      })}
                    />
                    <Button
                      {...data.course_faqs_cta_link}
                      color='inline'
                      icon
                    >
                      {data.course_faqs_cta_label.text}
                    </Button>
                  </div>
                </Container>
              </CourseViewSection>
            </>
          )
        }}
      </StickyLayout>
      <Modal ref={modalElement}>
        <div className='max-w-sm space-y-8 px-4'>
          <h6 className='h3'>Get our {data.title.text} curriculum</h6>
          <div>
            <HubspotForm
              formId={data.curriculum_form_guid}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}

CourseView.propTypes = {
  data: PropTypes.object.isRequired
}

export default CourseView
