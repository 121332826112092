import React from 'react'
import PropTypes from 'prop-types'

// Generic
import HtmlContent from '../../shared/HtmlContent/HtmlContent'

// Local
import CourseViewOverviewDetailsItem from './CourseViewOverviewDetailsItem'

// Icons
import IconInfo from '../../../assets/img/icon-info.svg'

const CourseViewOverviewDetails = (props) => {
  const { data } = props

  return (
    <div className='sm:flex justify-between space-y-4 sm:space-y-0 sm:space-x-4 shadow-lg p-4 md:px-10 md:py-8 mb-12'>
      <CourseViewOverviewDetailsItem
        title='Start date'
        header={data.course_start_date}
        body={data.course_location.text}
      />
      <CourseViewOverviewDetailsItem
        title='Duration'
        header={data.course_duration.text}
        body={<><span className='font-medium'>{data.course_duration_days.text}</span> ({data.course_duration_times.text})</>}
      />
      <CourseViewOverviewDetailsItem
        title='Price'
        header={<>£{data.course_price_total}</>}
        body={
          data.course_price_breakdown.map((breakdown, breakdownIndex) => {
            return (
              <div
                key={breakdownIndex}
                className='whitespace-no-wrap'
              >
                <span className='font-medium'>£{breakdown.course_price_breakdown_price}</span> {breakdown.course_price_breakdown_description.text}
                {breakdown.course_price_breakdown_extra_info.html && (
                  <div className='inline relative ml-1 group'>
                    <IconInfo className='inline-block w-4 fill-current text-primary align-text-bottom cursor-pointer' />
                    <div className='hidden group-hover:block w-64 absolute bottom-1/1 right-0 xl:-right-16 bg-midnight p-6 mb-2'>
                      <HtmlContent
                        html={breakdown.course_price_breakdown_extra_info.html}
                        className='text-white whitespace-normal c-prose'
                      />
                      <div className='block text-lg text-midnight absolute top-1/1 right-0 xl:right-16 z-10'>
                        <span className='block triangle-down' />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )
          })
        }
      />
    </div>
  )
}

CourseViewOverviewDetails.propTypes = {
  data: PropTypes.object.isRequired
}

export default CourseViewOverviewDetails
