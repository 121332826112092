import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const CourseViewSection = (props) => {
  const { id, className, children, updateCurrentNavBasedOnScroll } = props
  const element = useRef(null)

  /**
   * Simple function to trigger the parent update function
   * @return {Void}
   */
  const handleScroll = () => {
    updateCurrentNavBasedOnScroll(element, id)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    // Remove when component is unmounted, remove the scroll event
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [updateCurrentNavBasedOnScroll])

  return (
    <section
      id={id}
      className={className}
      ref={element}
    >
      {children}
    </section>
  )
}

CourseViewSection.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  updateCurrentNavBasedOnScroll: PropTypes.func.isRequired
}

export default CourseViewSection
