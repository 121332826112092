import React from 'react'
import PropTypes from 'prop-types'

// Generic
import Pill from '../../shared/Pill/Pill'

const CourseViewOverviewDetailsItem = (props) => {
  const { body, header, title } = props

  return (
    <div>
      <Pill className='mb-2'>
        {title}
      </Pill>
      <p className='h4 text-primary mb-1'>{header}</p>
      <div className='text-sm space-y-1'>{body}</div>
    </div>
  )
}

CourseViewOverviewDetailsItem.propTypes = {
  body: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.element
  ]).isRequired,
  header: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ]).isRequired,
  title: PropTypes.string.isRequired
}

export default CourseViewOverviewDetailsItem
