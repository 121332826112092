import slugify from 'slugify'

/**
 * Convert string to a slug
 * @param  {String} str
 * @return {String}
 */
const toSlug = (str) => {
  return slugify(str, {
    lower: true,
    remove: /[*+~.,()'"!:@]/g
  })
}

export default toSlug
